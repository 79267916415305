<template>
  <v-row align="center" justify="justify-space-between" no-gutters>
    <v-col cols="10">
      <div align="left" class="pl-4">
        <v-img
          position="center center"
          max-height="250"
          max-width="550"
          src="@/assets/Rupali_investement11.png"
        ></v-img>
      </div>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="2">
      <v-img
        max-height="150"
        max-width="150"
        src="@/assets/logo_mujib1.png"
        class="justify-end"
      >
        <div class="fill-height repeating-gradient"></div>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Header",

  components: {
    // Logo: () => import("@/components/logo.vue"),
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleClass: function () {
      this.isActive = !this.isActive;
    },
  },
};
</script>
